<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="headline d-flex flex justify-center align-center">
        <div class="ff_patrick-hand text-bold font-weight-bold">Survey</div>

        <v-btn icon class="button button__icon" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-img
        src="../assets/brunjar-qr-code-ecommerce-survey.png"
        width="300"
        class="mx-auto mb-8"
      ></v-img>
      <v-card-text class="card text-center"
        >Scan the QR code or click on the button to fill on the
        survey</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="button_blue mb-4">
          <a
            class="white--text"
            href="https://forms.gle/BDWqZAbbuoXv13Ks5"
            target="_blank"
            >Help Fill Survey</a
          >
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: true
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variable.scss";
.card {
  font-size: 1.3rem !important;
}

.button {
  position: relative;
}

.button__icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.button_blue {
  background-color: $blue !important;
}

a {
  text-decoration: none !important;
}
</style>
