<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col>
        <v-img
          src="../assets/brunjar-logo.png"
          class="rounded-circle mx-auto"
          :max-width="$vuetify.breakpoint.mobile ? '200' : '500'"
        ></v-img>

        <h1
          class="lh-10 ff_patrick-hand text_smaller text-center mt-16 white--text px-10 px-md-0"
        >
          We are a community based e-commerce space for you
        </h1>

        <p class="text-center mt-6 white--text">
          Coming Soon!
        </p>

        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variable.scss";

p {
  font-size: 1.2rem;
}
</style>
