<template>
  <v-app>
    <v-main class="main">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  })
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap");
@import "@/assets/scss/_variable.scss";

.main {
  background-color: $blue;
}

.ff_patrick-hand {
  font-family: "Patrick Hand" !important;
}

// $body-font-family: "Patrick Hand";

// .v-application {
//   font-family: $body-font-family, sans-serif !important;
// }
</style>
